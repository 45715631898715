<template>
  
    <div class="h-16 w-160 flex mb-2">
    
        <div class="h-full w-16 flex-none sticky left-0 bottom-0 flex flex-col justify-center items-center bg-tnrbody px-1 z-20">
            <span class="text-3xs font-semibold text-center" :class="{'text-font-gray': !sl}">{{data.name}}</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-font-gray': !sl}">{{data.tnr | reduceBigNumbers()}}€</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-font-gray': !sl}">{{data.tnr_csp | reduceBigNumbers()}}€</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-good': data.tnr_csp_vs_plan > 0, 'text-danger': data.tnr_csp_vs_plan <= 0}">{{data.tnr_csp_vs_plan | numberFormat(2)}}%</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-font-gray': !sl}">{{data.tnr_rate_hour | reduceBigNumbers()}}€/h</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-font-gray': !sl}">{{data.tnr_charged_hour | reduceBigNumbers()}}h</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-good': data.tnr_charged_vs_plan > 0, 'text-danger': data.tnr_charged_vs_plan <= 0}">{{data.tnr_charged_vs_plan | numberFormat(2)}}%</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-good': data.effective_utilization > 0, 'text-danger': data.effective_utilization <= 0}">{{data.effective_utilization | numberFormat(2)}}%</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-font-gray': !sl}">{{data.fte_total_csp | reduceBigNumbers()}}h</span>
        </div>

        <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
            <span class="text-xxs font-semibold" :class="{'text-font-gray': !sl}">{{data.charged_hour_total_csp_fte | reduceBigNumbers()}}h/CSP</span>
        </div>

    </div>

</template>

<script>
export default {
    props:['data', 'sl']
}
</script>

<style>

</style>