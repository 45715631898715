<template>
  
    <div class="h-full w-full rounded-lg p-2 flex flex-col module relative">

        <Loader :loading="loading" />

        <div class="h-auto flex flex-row justify-start items-center py-1">

            <span class="text-font-gray text-lg font-semibold mr-auto">SL</span>

            <multiselect v-if="slOptions" class="w-32" label="name" v-model="slSelected" :options="slOptions" />

        </div>

        <div class="flex-1 flex flex-col mt-2 mb-6 overflow-hidden">

            <div class="h-full w-full overflow-auto no-scrollbar bg-tnrbody relative">

                <div class="h-16 w-160 flex mb-2 bg-tnrbody sticky top-0">
    
                    <div class="h-full w-16 flex-none sticky left-0 bg-tnrbody"></div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">Total NET Revenue Actual</span>
                    </div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">TNR per Total CSP FTE (In 0000s) Actual</span>
                    </div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">TNR per Total CSP FTE (In 0000s) Actual % vs plan</span>
                    </div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">TNR Rate per Hour Actual</span>
                    </div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">Total Chargued hours Actual</span>
                    </div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">Total Charged Hours Inc/(Dec) % vs Plan</span>
                    </div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">Efective Utilization % Actual</span>
                    </div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">FTES total CSP Actual</span>
                    </div>
    
                    <div class="h-full w-16 flex-none flex flex-row justify-center items-center px-2">
                        <span class="font-semibold text-3xs text-font-gray text-center">Chargued hours per Total CSP FTE Actual</span>
                    </div>
    
                </div>

                <div class="h-2 w-160 bg-module-top"></div>

                <tnrel :data="slSelected" :sl="true"/>

                <separator />

                <div class="flex flex-row justify-start items-center p-2 sticky left-0">
                    <span class="text-font-gray font-semibold text-sm">SMU</span>
                </div>

                <tnrel v-for="(el,index) in smus" :key="index" :data="el"/>

                <div v-if="smus.length == 0" class="h-48 flex flex-col justify-center items-center sticky left-0">
                    <span class="text-xs">No SMU data</span>
                </div>


            </div>


        </div>
        

    </div>

</template>

<script>
import tnrel from '../../components/Mobile/ProjectsManagement/tnrel.vue'
import Loader from '../../components/Globals/Loader.vue';
import { state, actions } from '@/store';
import Separator from '../../components/Globals/Separator.vue';

export default {
    components:{
    tnrel,
    Loader,
    Separator
},
    data(){
        return{
            loading: false,
            slOptions: false,
            smus: false,
            slSelected: false
        }
    },
    methods:{
        load() {
            this.loading = true;
            this.axios.get('projects_management/tnr_sl_competency', {params: this.params}).then(response => {
                this.slOptions = response.data.data;
                this.slSelected = response.data.data[0]
                this.smus = this.slSelected.smus
                this.loading = false
            })
        }
    },
    computed:{
        params() { return state.globalParams },
        rolSelected() { return state.rolSelected }
    },
    watch:{
        slSelected(n,o){
            this.smus = n.smus
        },
        rolSelected(){
            if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'ROL_SL' || state.rolSelected.role_type_link == 'competencyLeader' || state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                
            } else {
                this.$router.push({name:'homeMobile'})
            }
        },
        params(){
            if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'ROL_SL' || state.rolSelected.role_type_link == 'competencyLeader' || state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                
            } else {
                this.$router.push({name:'homeMobile'})
            }
        }
    },
    mounted(){
        if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'ROL_SL' || state.rolSelected.role_type_link == 'competencyLeader' || state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                
        } else {
            this.$router.push({name:'homeMobile'})
        }
        this.load();
    }
}
</script>

<style>

</style>